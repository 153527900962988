


























import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useInternalContract } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DataListInternalContract } from "@/models/interface/salesOrder.interface";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class SelectIc extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: String, default: false })
  branchId!: string;

  @Prop({ required: false, type: String, default: false })
  customerId!: string;

  options: Option<DataListInternalContract>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  @Watch("customerId")
  onChangeCustomerId(): void {
    this.preFetch();
  }

  @Watch("branchId")
  onChangeBranchId(): void {
    this.preFetch();
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  fetchOptions(params = new RequestQueryParams()): void {
    const { findAll, toOptions } = useInternalContract();
    this.loading = true;
    findAll(params)
      .then(res => {
        this.options = toOptions(res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onSearch(value = ""): void {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const query: Array<string> = [];
    const defaultQuery: string = this.buildQuery();

    if (value) {
      query.push(builder.push(["documentNo", value], { like: "both" }).build());
    }

    if (defaultQuery) {
      query.unshift(defaultQuery);
    }

    params.search = query.join(builder.AND);

    this.fetchOptions(params);
  }

  findOption(value: string): Option<DataListInternalContract> | undefined {
    return this.options.find(item => item.value === value);
  }

  buildQuery(): string {
    const builder = new SearchBuilder();
    const query: Array<string> = [];

    if (this.branchId) {
      query.push(builder.push(["branch.secureId", this.branchId]).build());
    }

    if (this.customerId) {
      query.push(builder.push(["customer.secureId", this.customerId]).build());
    }

    return query.join(builder.AND);
  }

  preFetch(): void {
    const params = new RequestQueryParams();
    params.search = this.buildQuery();
    this.fetchOptions(params);
  }
}
