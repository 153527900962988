var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_invoice_controlling_report") } },
        [
          _c(
            "a-form-model",
            {
              ref: "formModel",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "wrapper-col": { span: 12 },
                "label-col": { span: 8 },
                "label-align": "left"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_branch"),
                            prop: "branchId"
                          }
                        },
                        [
                          _c("SelectBranch", {
                            on: { "update:meta": _vm.onChangeBranch },
                            model: {
                              value: _vm.form.branchId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "branchId", $$v)
                              },
                              expression: "form.branchId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_date"), prop: "date" } },
                        [
                          _c("a-range-picker", {
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              placeholder: [
                                _vm.$t("lbl_start_date"),
                                _vm.$t("lbl_end_date")
                              ]
                            },
                            model: {
                              value: _vm.form.date,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "date", $$v)
                              },
                              expression: "form.date"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer"),
                            prop: "customerId"
                          }
                        },
                        [
                          _c("SelectCustomer", {
                            on: { "update:meta": _vm.onChangeCustomer },
                            model: {
                              value: _vm.form.customerId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "customerId", $$v)
                              },
                              expression: "form.customerId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_unit_code"),
                            prop: "unitCode"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { "allow-clear": "" },
                            model: {
                              value: _vm.form.unitCode,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "unitCode", $$v)
                              },
                              expression: "form.unitCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_internal_contract_no"),
                            "label-align": "right",
                            prop: "icId"
                          }
                        },
                        [
                          _c("SelectIc", {
                            attrs: {
                              "branch-id": _vm.form.branchId,
                              "customer-id": _vm.form.customerId
                            },
                            on: { "update:meta": _vm.onChangeIc },
                            model: {
                              value: _vm.form.icId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "icId", $$v)
                              },
                              expression: "form.icId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_status"),
                            "label-align": "right",
                            prop: "status"
                          }
                        },
                        [
                          _c("SelectMasterType", {
                            attrs: {
                              name: "INVOICE_CONTROLLING_REPORT_STATUS"
                            },
                            model: {
                              value: _vm.form.status,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_by_invoice"),
                            "label-align": "right",
                            prop: "isInvoiced"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              options: _vm.isInvoicedOptions,
                              "allow-clear": ""
                            },
                            model: {
                              value: _vm.form.isInvoiced,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "isInvoiced", $$v)
                              },
                              expression: "form.isInvoiced"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-space",
                        [
                          _c("a-button", { on: { click: _vm.handleReset } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                          ]),
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.find,
                                "html-type": "submit"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.dataReport.data,
              "row-key": function(r, i) {
                return i
              },
              size: "small",
              loading: _vm.loading.find,
              scroll: { x: "calc(100% + 900px)" },
              pagination: {
                showTotal: function() {
                  return _vm.$t("lbl_total_items", {
                    total: _vm.dataReport.totalElements
                  })
                },
                showSizeChanger: true,
                pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                current: _vm.pagination.page,
                total: _vm.dataReport.totalElements
              }
            },
            on: { change: _vm.onChangeTable },
            scopedSlots: _vm._u([
              {
                key: "ellipsis",
                fn: function(text) {
                  return [
                    _c("a-tooltip", { attrs: { placement: "topLeft" } }, [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(" " + _vm._s(text) + " ")
                      ]),
                      _vm._v(" " + _vm._s(text) + " ")
                    ])
                  ]
                }
              },
              {
                key: "nullable",
                fn: function(text) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(text || "-") + " ")
                  ])
                }
              },
              {
                key: "currency",
                fn: function(text) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(_vm._f("currency")(text)) + " ")
                  ])
                }
              },
              {
                key: "date",
                fn: function(text) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(_vm._f("date")(text)) + " ")
                  ])
                }
              },
              {
                key: "unitCode",
                fn: function(text, row) {
                  return [
                    _vm._v(" " + _vm._s(row.unitCode) + " "),
                    _c("a-icon", {
                      attrs: { type: "select", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.showUnit(row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c(
            "div",
            { staticClass: "mt-2 text-right" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading.download },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.$t("lbl_unit_histories"), width: "50%" },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.modal.toggle()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.modal.visible,
            callback: function($$v) {
              _vm.$set(_vm.modal, "visible", $$v)
            },
            expression: "modal.visible"
          }
        },
        [
          _c("a-table", {
            attrs: {
              columns: _vm.columnsUnitHistories,
              "data-source": _vm.modal.data,
              pagination: {
                showTotal: function(total) {
                  return _vm.$t("lbl_total_items", { total: total })
                }
              },
              size: "small",
              "row-key": function(r, i) {
                return i
              }
            },
            scopedSlots: _vm._u([
              {
                key: "nullable",
                fn: function(text) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(text || "-") + " ")
                  ])
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }