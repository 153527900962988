






































































































































































import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import SelectMasterType from "@/components/custom/select/SelectMasterType.vue";
import { SelectIc } from "@/components/InvoiceControllingReport";
import { useBlob, useDate, useInvoiceControllingReport } from "@/hooks";
import { FilterField, InvoicedEnum } from "@/hooks/useInvoiceControllingReport";
import MNotificationVue from "@/mixins/MNotification.vue";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  ReportInvControllingResponseDto,
  UnitHistoryDto,
} from "@/models/interface/invoice-controlling-report";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { DataListInternalContract } from "@/models/interface/salesOrder.interface";
import { ListContactDataDto } from "@interface/contact-data";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Ref } from "vue-property-decorator";

@Component({
  components: {
    SelectMasterType,
    SelectBranch,
    SelectCustomer,
    SelectIc,
  },
})
export default class InvoiceControllingReport extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  @Ref("formModel")
  formModel!: FormModel;

  dataReport: Pagination<ReportInvControllingResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  pagination = {
    page: ONE,
    limit: DEFAULT_PAGE_SIZE,
  };

  form: FilterField = {
    branchId: "",
    branchName: "",
    isInvoiced: null,
    date: [],
    icId: "",
    customerId: "",
    customerName: "",
    status: "",
    unitCode: "",
    icNumber: "",
  };

  formRules = {
    branchId: [
      {
        required: true,
        message: () => this.$t("lbl_validation_required_error"),
      },
    ],
    date: [
      {
        required: true,
        message: () => this.$t("lbl_validation_required_error"),
      },
    ],
  };

  columns = [
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      width: "300px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_address"),
      dataIndex: "customerAddress",
      ellipsis: true,
      scopedSlots: { customRender: "ellipsis" },
    },
    {
      title: this.$t("lbl_customer_location"),
      dataIndex: "customerLocation",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      scopedSlots: { customRender: "unitCode" },
    },
    {
      title: this.$t("lbl_internal_contract_no"),
      dataIndex: "refNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_contract_period"),
      dataIndex: "contractPeriod",
      width: "250px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoiceNumber",
      width: "200px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_billing_period"),
      dataIndex: "billingPeriod",
      width: "300px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "invoiceStatus",
      width: "150px",
      scopedSlots: { customRender: "nullable" },
    },
  ];

  columnsUnitHistories = [
    {
      title: this.$t("lbl_old"),
      dataIndex: "unitOld",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_new"),
      dataIndex: "unitNew",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_change_date"),
      dataIndex: "changeDate",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_batu_number"),
      dataIndex: "batuNumber",
      scopedSlots: { customRender: "nullable" },
    },
  ];

  isInvoicedOptions: Array<Option> = [
    {
      label: this.$t("lbl_yes").toString(),
      key: 0,
      value: "YES" as InvoicedEnum,
    },
    {
      label: this.$t("lbl_no").toString(),
      key: 1,
      value: "NO" as InvoicedEnum,
    },
  ];

  loading = {
    find: false,
    download: false,
  };

  modal = {
    visible: false,
    data: [] as Array<UnitHistoryDto>,
    toggle(): void {
      this.visible = !this.visible;
    },
  };

  fetchReport(params?: RequestQueryParams): void {
    const { findAll } = useInvoiceControllingReport();

    this.loading.find = true;
    findAll(params)
      .then(res => {
        this.dataReport = res;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  onSubmit(): void {
    this.formModel.validate((valid: boolean) => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }
      this.pagination.page = FIRST_PAGE;
      const params: RequestQueryParams = this.buildParamsList();
      this.fetchReport(params);
    });
  }

  handleReset(): void {
    this.formModel.resetFields();
  }

  onChangeTable(pagination: {
    total: number;
    current: number;
    pageSize: number;
  }): void {
    this.pagination.page = pagination.current;

    if (this.pagination.limit !== pagination.pageSize) {
      this.pagination.page = FIRST_PAGE;
    }

    this.pagination.limit = pagination.pageSize;

    const params = this.buildParamsList();
    params.page = this.pagination.page - ONE;
    this.fetchReport(params);
  }

  buildSearch(form: FilterField): string {
    const { filterBy } = useInvoiceControllingReport();
    const searchQuery: string = filterBy(form);
    return searchQuery;
  }

  buildParamsList(): RequestQueryParams {
    const params = new RequestQueryParams();
    const { toStartDay, toEndDay } = useDate();

    if (this.form.date && this.form.date.length > 0) {
      const [start, end] = this.form.date;
      params.dateFrom = toStartDay(start).format();
      params.dateTo = toEndDay(end).format();
    }

    if (this.form.isInvoiced) {
      params.invoiceNumber = this.form.isInvoiced.toUpperCase();
    }

    params.limit = this.pagination.limit;
    params.search = this.buildSearch(this.form);

    return params;
  }

  buildParamsDownload(): RequestQueryParams {
    const { buildReportHeader } = useInvoiceControllingReport();
    const params: RequestQueryParams = this.buildParamsList();
    params.params = buildReportHeader(this.form);
    return params;
  }

  handleDownload(): void {
    const { download } = useInvoiceControllingReport();
    const { toDownload } = useBlob();
    const params: RequestQueryParams = this.buildParamsDownload();

    this.loading.download = true;
    download(params)
      .then(res => {
        toDownload(res, "invoice_controlling_report.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  onChangeBranch(e: Option<DataWarehouseBranch> | undefined): void {
    this.form.branchName = e?.meta?.name || "";
    this.form.branchId = e?.meta?.id || "";
  }

  onChangeCustomer(e: Option<ListContactDataDto> | undefined): void {
    this.form.customerId = e?.meta?.id || "";
    this.form.customerName = e?.meta?.fullName || "";
  }

  showUnit(row: ReportInvControllingResponseDto): void {
    this.modal.data = row.unitHistories;
    this.modal.toggle();
  }

  onChangeIc(ev: Option<DataListInternalContract> | undefined): void {
    this.form.icNumber = ev?.label || "";
    this.form.icId = ev?.meta?.id || "";
  }
}
